export default {
  en: 'Engelska',
  sv: 'Svenska',
  logout: 'Logga ut',
  login: 'Logga in',
  password: 'Lösenord',
  password_confirm: 'Bekräfta lösenord',
  email: 'Email',
  price: 'Pris',
  price_per_hour: 'Pris per timma',
  back: 'Tillbaka',
  apply_date: 'Sätt datum',
  update: 'Uppdatera',
  create: 'Skapa',
  delete: 'Radera',
  select: 'Välj',
  not_selected: 'Ej vald',
  no_results: 'Inget resultat',
  no_name: 'Inget namn',
  no_search_results: 'Inget sökresultat',
  actions: 'Åtgärder',
  cancel: 'Avbryt',
  fetch: 'Hämta',
  confirm: 'Bekräfta',
  add: 'Lägg till',
  save: 'Spara',
  show_all: 'Visa alla',
  per_page: 'Antal per sida',
  clear: 'Klar',
  search: 'Sök',
  previous: 'Föregående',
  next: 'Nästa',
  apply: 'Använd',
  fixed: 'Fast',
  hourly: 'Löpande',
  noname: 'Noname',
  activate: 'Aktivera',
  deactivate: 'Inaktivera',
  discount: 'Rabatt',
  yes: 'Ja',
  view: 'Visa',
  send: 'Skicka',
  user: 'Användare',
  reassign: 'Omtilldela',
  comments: 'Kommentarer',
  no: 'Nej',
  empty: 'Tom',
  filters: 'Filter',
  apply_filters: 'Använd filter',
  reset_filters: 'Återställ filter',
  reset: 'Återställ',
  edit: 'Redigera',
  approve: 'Godkänn',
  refuse: 'Avslå',
  month: 'Månad',
  months: 'Månader',
  year: 'År',
  comment: 'Kommentar',
  week: 'Vecka',
  disabled: 'Inaktiverad',
  close: 'Stäng',
  text: 'Text',
  total: 'Summa',
  discard: 'Släng',
  all: 'Alla',
  loading: 'Laddar...',
  type_of_search: 'Skriv för att söka eller välj från listan',
  week_short: 'V',
  move_week_forward: 'Flytta resterande framåt',
  mark_week_as_done: 'Klarmarkera vecka',
  planned: 'Planerad',
  draft: 'Utkast',
  undo: 'Ångra',
  redo: 'Gör om',
  deleted: 'Raderad',
  restore: 'Återställ',
  add_time: 'Lägg till tid',
  type_to_search: 'Skriv för att söka',
  my_profile: 'Min profil',
  created: 'Skapat',
  updated: 'Uppdaterat',
  forecasted: 'Prognos',
  scheduled: 'Schemalagd',
  to_project_planning: 'Till projektplanering',
  add_new: 'Lägg till',
  personal_number: 'Personnummer',
  phone: 'Telefon',
  customer: 'Kund',
  show_more: 'Visa mer',
  show_less: 'Visa mindre',
  none: 'Ingen',
  click_to_report_time: 'Klicka för att rapportera tid',
  this_event_is_set_to_done_reopen_it_to_report_time:
    'Detta event är klarmarkert. Återöppna det för att rapportera mer tid',
  show_all_planned_tasks: 'Visa alla planerade uppgifter',
  time_future_activity: 'Det går inte rapportera tid i framtiden på interna projekt',

  confirms: {
    unsaved: {
      title: 'Det verkar som om du har redigerat något.',
      text: 'Om du lämnar innan du har sparat kommer dina ändringar att gå förlorade. Är du säker på att du vill lämna sidan?',
    },
    save: {
      title: 'Är du säker att du vill spara ändringar?',
    },
    cancel: {
      title: 'Är du säker att du vill radera ändringar?',
    },
    delete: {
      title: 'Radera "{name}"',
      text: 'Är du säker att du vill radera <strong>{name}</strong>?',
    },
  },

  not_found: {
    title: '404 - Sidan kunde inte hittas',
    text: 'Sidan du söker finns inte.',
  },

  messages: {
    has_been_created: '{name} har skapats',
    has_been_updated: '{name} har uppdaterats',
    have_been_updated: '{name} har uppdaterats',
    has_been_added: '{name} har lagts till',
    has_been_deleted: '{name} har raderats',
    has_been_closed: '{name} har stängts',
    has_been_activated: '{name} har aktiverats',
    forbidden_to_move: 'Förbjudet att flytta',
  },

  project: 'Projekt',
  projects: 'Projekt',

  task: 'Uppgift',
  tasks: 'Uppgifter',

  tooltip: {
    edit: 'Redigera "{name}"',
    delete: 'Radera "{name}"',
    complete: 'Klarmarkera',
    reopen: 'Återöppna',
    prioritise: 'Prioritera',
    deprioritize: 'Avprioritera',
  },

  apply_filters_to_show_data: 'Använder filter för att visa data',
  support_in_weplan_for: 'Support i WePlan för',
};
