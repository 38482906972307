export default {
  index: {
    title: 'Employees',
    help: '',
    create: 'Create employee',

    filters: {
      name: 'Employee name',
    },
  },

  create: {
    title: 'Create employee',
    help: '',
  },

  edit: {
    title: 'Edit employee: {name}',
    help: '',
    edit_in_admin: 'Edit in Admin',
  },

  form: {
    info_title: 'Info',
    settings_title: 'Settings',
    internal_price_changes_title: 'Internal price changes',
    external_price_changes_title: 'External price changes',
    internal_price_title: 'Internal price',
    external_price_title: 'External price',
    current_internal_price_title: 'Current internal price',
    current_external_price_title: 'Current external price',
  },

  status: {
    active: 'Active',
    blocked: 'Blocked',
    invited: 'Invited',
  },

  role: {
    super_admin: 'Super admin',
    customer_admin: 'Customer admin',
    user: 'User',
  },

  system_role: {
    employee: 'Employee',
    manager: 'Manager',
  },
  salary_type: {
    monthly: 'Monthly salary',
    hourly: 'Per hour',
  },

  empty: 'No employees',

  // Attributes
  attributes: {
    name: 'Name',
    email: 'Email',
    phone_number: 'Phone number',
    personal_number: 'Personal number',
    system_role: 'System role',
    work_title: 'Work title',
    employee_number: 'Employee number',
    manager: 'Manager',
    office: 'Office',
    department: 'Department',
    hourly_price: 'Hourly price',
    internal_price: 'Internal cost',
    work_rate: 'Current work rate',
    status: 'Status',
    firm_admin: 'Firm admin',
    time_balance: 'Time bank (Flex)',
    salary_type: 'Salary type',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" employee',
    destroy: 'Delete "{name}" employee',
    additional_information_required: 'Additional information required',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete employee',
      text: 'Are you sure you want to delete <strong>{name}</strong> employee from the system?',
    },
    destroy_price: {
      title: 'Delete user price',
      text: 'Are you sure you want to delete user price from the system?',
    },
  },
  portal: {
    status: {
      active: 'Active',
      inactive: 'Inactive',
      not_invited: 'Not invited',
      invited: 'Invited',
    },
    confirm: {
      activate: {
        title: 'Activate user',
        text: 'Are you sure you want to grant the <strong>{name}</strong> access to the Customer portal?',
      },
      deactivate: {
        title: 'Deactivate user',
        text: 'Are you sure you want to deny the <strong>{name}</strong> access to the Customer portal?',
      },
    },
  },

  // Work rates
  work_rates: {
    title: 'Work rates',
    history: {
      title: 'History',
    },
    create: {
      title: 'Create employment rate',
    },
    edit: {
      title: 'Edit employment rate',
    },
    attributes: {
      date: 'Date',
      rate: 'Rate',
    },
    flexible_time: {
      even_weeks: 'Even week number',
      odd_weeks: 'Odd week number',
      attributes: {
        day: 'Day',
        default: 'Default',
        employee: 'Employee',
        rate: 'Rate',
        total: 'Total',
        net_total: 'Net total',
      },
    },
    form: {
      date: 'Change from date',
      rate: 'Employment rate',
      is_flexible: 'Variable rate',
      is_flexible_week: 'Schedule is the same for all weeks',
    },
    confirm: {
      destroy: {
        title: 'Delete work rate',
        text: 'Are you sure you want to delete this work rate?',
      },
    },
  },

  internal_price: {
    title: 'Edit internal cost',
    history: {
      title: 'History',
    },
    attributes: {
      date: 'Date',
      price: 'Cost',
    },
    form: {
      date: 'Change from date',
      price: 'Internal cost',
    },
  },

  external_price: {
    title: 'Edit hourly price',
    history: {
      title: 'History',
    },
    attributes: {
      date: 'Date',
      price: 'Price',
    },
    form: {
      date: 'Change from date',
      price: 'Price',
    },
  },

  deadlines: {
    title: 'Deadlines for w.{weekNumber}',
    show_completed: 'Show completed task',
  },

  profile: {
    title: 'My profile',
    contact_information: 'Contact information',
    other_info: 'Other info',
    my_prices: 'My prices',
  },

  vehicle: {
    type: {
      private_car: 'Private car',
      company_car_diesel: 'Company car: Diesel',
      company_car_petrol: 'Company car: Petrol',
      company_car_electric: 'Company car: Electric',
    },
    index: {
      title: 'My vehicles',
      create: 'Create vehicle',
    },
    create: {
      title: 'Create vehicle',
    },
    edit: {
      title: 'Edit vehicle',
    },
    empty: 'No vehicles',
    attributes: {
      name: 'Name',
      type: 'Type of vehicle',
      created_at: 'Added to my profile',
    },
    tooltip: {
      edit: 'Edit "{name}" vehicle',
      delete: 'Delete "{name}" vehicle',
    },
    confirm: {
      delete: {
        title: 'Delete vehicle',
        text: 'Are you sure you want to delete <strong>{name}</strong> vehicle?',
      },
    },
  },

  todos: {
    todos: {
      title: 'ToDo & Questions',
    },
    informations: {
      title: 'Info (last 7 days)',
    },
    tooltip: {
      show_with_deadline: 'Show with deadline',
      show_all: 'Show all',
      show_hidden: 'Show closed',
      comment: 'Comment',
      complete: 'Complete',
      hide: 'Hide for me',
      show_history: 'Show history',
      answer: 'Answer',
      answered: 'Answered',
    },
  },

  stats: {
    title: 'WeConnect Portal',
    tasks: 'My tasks',
    messages: 'My messages',
  },
  notifications: {
    title: 'Notifications',
    todos: 'Todo',
    infos: 'Info',
  },

  time_balance: {
    title: 'Time bank (Flex) - {name}',
    current_balance: 'Current balance',
    manual_edit: 'Transactions',
    attributes: {
      date: 'Date',
      change: 'Change (h)',
      balance: 'Balance (h)',
      description: 'Description',
    },
    type: {
      deposit: 'Add hours',
      debit: 'Deduct hours',
    },
    form: {
      type: 'Type',
      sum: 'Number of hours',
      comment: 'Comment',
    },
  },
};
